import { Injectable } from '@angular/core';
import { Router, Route } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ToastController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { InfoSociete } from '../types/info-societe';

@Injectable()
export class AuthenticationService {


	constructor(
		public global: GlobalService,
		private router: Router,
		private storage: Storage,
		private platform: Platform,
		public toastController: ToastController,
		public http: HttpClient
	) {
		/*	this.platform.ready().then(() => {
				this.ifLoggedIn();
			});*/
	}


	public isAuthenticated = false;
	public error = false;

	async getloginfromdb(key) {
		const headers = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded; charset=UTF-8")
		const params = new HttpParams()
			//.set('key', (JSON.stringify(key)))
			.set('table', ("users"))

		console.log("login load")

		const url = "https://restapi11.edservices.fr/api/process.php"
		const data = await this.http.get<any[]>(url, { params: params, headers: headers }).toPromise();
		console.log("getloginfromdb",data)
		const userfound = data.filter(d=>d.user == key.user && d.password == key.password)
		if ( userfound.length > 0) {
			console.log("userfound",userfound[0])
			this.global.users = data.filter(d=>d.tableprefix == userfound[0].tableprefix)
			this.global.userskeyvalue = this.global.users.map(x=>{return {key:x.userid,value:x.user}})
			this.global.userskeyvalue.unshift({key:"0",value:"-"})
			console.log("this.global.userskeyvalue ",this.global.userskeyvalue )
		} else {
			console.error("user not found")
		}
		return userfound
	}

	async loadinfo(data) {
		this.global.tableprefix = data[0]["tableprefix"] + "_"
		this.global.userid = data[0]["userid"]
		this.global.user = data[0]

		const headers = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded; charset=UTF-8")
		const params = new HttpParams()
			.set('table', (this.global.tableprefix + "settings"))

		console.log("💾 global settings load", params)

		const url = "https://restapi11.edservices.fr/api/process.php"
		const tmp = await this.http.get<any[]>(url, { params: params, headers: headers }).toPromise()
		console.log("tmp", JSON.parse(tmp[0].data))
		this.global.infoSociete = new InfoSociete().fromObj(JSON.parse(tmp[0].data))
		console.log("this.global.infoSociete",this.global.infoSociete)
		this.global.infoSociete_raisonsociale = this.global.infoSociete.raisonsocial;
	}

	async login(username, password): Promise<Observable<boolean>> {
		this.error = false;
		// Implement your authentication logic here
		// For demo purposes, we'll just accept any username/password

		const data = await this.getloginfromdb({ user: username, password: password });

		console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx login load", data);

		if (data.length == 0) {
			this.error = true;
			this.isAuthenticated = false;
			return of(this.isAuthenticated);
		}

		await this.loadinfo(data)

		var response = {
			user_id: data[0]["userid"],
			user_name: data[0]["user"],
			token: data[0]["token"]
		};
		this.storage.set('USER_INFO', response).then((response) => {
			this.router.navigate(['factures']);
			this.error = false;
			this.global.authState.next(true);
			console.log("login ok");
		});

		this.error = false;
		this.isAuthenticated = true;
		return of(this.isAuthenticated);


	}

	logout(): void {
		console.log("logout")
		this.storage.remove('USER_INFO').then(() => {
			this.router.navigate(['login']);
			this.global.authState.next(false);
		});

		this.isAuthenticated = false;
	}

	get isLoggedIn(): boolean {
		return this.isAuthenticated;
	}

	async ifLoggedIn() {
		console.log("ifLoggedIn")
		const response = await this.storage.get('USER_INFO')
		if (response) {
			console.log("response", response)

			const data = await this.getloginfromdb({ user: response["user_name"], token: response["token"] })

			console.log("ifLoggedIn getloginfromdb", data)
			if (data.length > 0) {
				await this.loadinfo(data)

				this.isAuthenticated = true;
				console.log("ifLoggedIn true")
				this.global.authState.next(true);
				this.router.navigate(['factures']);
			}
		}
	}

	async ifLoggedIn2(route: Route) {

		console.log("ifLoggedIn2", this.isAuthenticated)
		if (this.isAuthenticated) {
			console.log("already authenticated")
			return true;
		}
		const response = await this.storage.get('USER_INFO');


		console.log("response", response)

		if (response == null) {
			this.router.navigate(['login']);
			console.log("ifloggedin2 real res false");
			return false;
		}

		const data = await this.getloginfromdb({ user: response["user_name"], token: response["token"] });

		console.log("ifLoggedIn2 getloginfromdb", data)
		if (data.length > 0) {

			await this.loadinfo(data)

			this.isAuthenticated = true;
			this.global.authState.next(true)
			console.log("ifloggedin2 real res true", data[0]);
			return true;

		}
		this.router.navigate(['login']);
		console.log("ifloggedin2 real res false");
		return false;

	}

	/*login() {
		var response = {
			user_id: '007',
			user_name: 'test'
		};
		this.storage.set('USER_INFO', response).then((response) => {
			this.router.navigate(['factures']);
			this.authState.next(true);
			console.log("login ok");
		});
	}
	logout() {
		this.storage.remove('USER_INFO').then(() => {
			this.router.navigate(['login']);
			this.authState.next(false);
		});
	}
	isAuthenticated() {
		return this.authState.value;
	}*/
}