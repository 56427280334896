export interface IInfoSociete {
    db: number;
    id: number;
    raisonsocial: String;
    lastname: String;
    firstname: String;
    address1: String;
    address2: String;
    address3: String;
    postalcode: String;
    city: String;
    country: String;
    phonenumber: String;
    mobilenumber: String;
    faxnumber: String;
    email: String;
    numerotva: String;
    numerosiret: String;
    regimentreprise: String;
    capitalsocial: String;
    footerText: String;
    footerInvoiceTableText: String;
    iban: String;
    bic: String;
    logo: String;

    //produit
    produit_avectva: boolean
}

export class InfoSociete implements IInfoSociete {
    db: number;
    id: number;
    raisonsocial: string;
    lastname: String;
    firstname: String;
    address1: String;
    address2: String;
    address3: String;
    postalcode: String;
    city: String;
    country: String;
    phonenumber: String;
    mobilenumber: String;
    faxnumber: String;
    email: String;
    numerotva: String;
    numerosiret: String;
    regimentreprise: String;
    capitalsocial: String;
    footerText: String;
    footerInvoiceTableText: String;
    iban: String;
    bic: String;
    logo: String;

    //produit
    produit_avectva: boolean

    constructor() {
        this.id = 0;
        this.db = 0;
        this.raisonsocial = "";
        this.lastname = "";
        this.firstname = "";
        this.address1 = "";
        this.address2 = "";
        this.address3 = "";
        this.postalcode = "";
        this.city = "";
        this.country = "";
        this.phonenumber = "";
        this.mobilenumber = "";
        this.faxnumber = "";
        this.email = "";
        this.numerotva = "";
        this.numerosiret = "";
        this.regimentreprise = "";
        this.capitalsocial = "";
        
        this.footerInvoiceTableText = "Certifie sincère et véritable la présente facture arrêtée à la somme de {{totalTTC}}€ TTC";

        this.raisonsocial = "Cristal Flore";
        this.address1 = "41 Rue Adrien Michaut";
        this.postalcode = "54120";
        this.city = "Baccarat";
        this.country = "France";
        this.numerosiret = "84199597000016";
        this.numerotva = "FR58841995970";
        this.phonenumber = "03 83 75 41 36";
        this.mobilenumber = "06 82 66 41 71";
        this.faxnumber = "";
        this.email = "contact@cristalflore.com";

        this.iban = "FR76 3008 7336 6000 0205 4920 192"
        this.bic = "CMCFRPP";
        this.logo = "";

        this.produit_avectva = false
    }

    fromObj(obj:InfoSociete) {
        this.id = obj.id;
        this.raisonsocial = obj.raisonsocial
        this.lastname = obj.lastname
        this.firstname = obj.firstname
        this.address1 = obj.address1
        this.address2 = obj.address2
        this.address3 = obj.address3
        this.postalcode = obj.postalcode
        this.city = obj.city
        this.country = obj.country
        this.phonenumber = obj.phonenumber
        this.mobilenumber = obj.mobilenumber
        this.faxnumber = obj.faxnumber
        this.email = obj.email
        this.numerotva = obj.numerotva
        this.numerosiret = obj.numerosiret
        this.regimentreprise = obj.regimentreprise
        this.capitalsocial = obj.capitalsocial
        
        this.footerInvoiceTableText = obj.footerInvoiceTableText

        this.raisonsocial = obj.raisonsocial
        this.address1 = obj.address1
        this.postalcode = obj.postalcode
        this.city = obj.city
        this.country = obj.country
        this.numerosiret = obj.numerosiret
        this.numerotva = obj.numerotva
        this.phonenumber = obj.phonenumber
        this.mobilenumber = obj.mobilenumber
        this.faxnumber = obj.faxnumber
        this.email = obj.email

        this.iban = obj.iban
        this.bic = obj.bic
        
        this.logo = obj.logo

        this.produit_avectva = obj.produit_avectva
        return this;
    }
}

